.container {
    /* 85 высота до нижего контейнера, без бордер радуса книзу  */
    /*height: 85px;*/
    height: 105px;
    width: auto;
    margin-bottom: -20px;
    background: linear-gradient(255.41deg, #B340FA -10.33%, #7143F3 56.79%, #5262DA 84.88%), #5262DA;
    z-index: -1;
    padding: 30px 16px;
}

.creddy_logo {
    display: flex;
    justify-content: center;
}

.logo_svg {
    width: 107px;
    height: 34px;
    fill: none;
}