.codeField {
    margin-top: 30px;

}
.codeField > div {
    width: 282px!important;
    display: flex!important;
    justify-content: center!important;
}

.button {
    margin-top: 27px;
    width: 282px;
    height: 50px;
    box-shadow: 0px 13px 15px #D2DCFF;
    border-radius: 10px;
}

.button:disabled {
    box-shadow: none;
}

.pers_data {
    margin-top: 60px;
    display: grid;
    justify-content: center;
    gap: 20px;
    font-size: 12px;
    color: var(--color-grey2);
    font-weight: 500;
}

.pers_data a {
    text-decoration: underline;
    color: var(--color-primary-2);
}