.Animation_rotate {
    animation: rotate .7s linear forwards infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg) ;
    }
    100% {
        transform: rotate(360deg);
    }
}