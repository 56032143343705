.password {
    margin-top: 10px;
    width: 282px!important;
}

.password > label {
    font-family: var(--font-graphik);
    font-style: normal;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 16px !important;
    transform: translateY(-1px) !important;
    /* identical to box height */


    color: #767676;

}

.password > input {
    padding-bottom: 0 !important;
    font-size: 25px !important;
    font-family: var(--font-graphik);
    height: 34px!important;
    line-height: 25!important;
}

.button {
    margin-top: 20px;
    width: 282px;
    height: 50px;
    background: #7045EB;
    box-shadow: 0px 13px 15px #D2DCFF;
    border-radius: 10px;
}