.container {
  background: var(--color-primary-4);
  border-radius: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: white;
  height: 48px;
  transition:  color 0.3s, box-shadow 0.2s;
  font-family: var(--font-sf);
  width: 100%;
}

/* margin */
.margin--medium {
  margin-bottom: 30px;
  margin-top: 30px;
}

.container:focus {
  border: 1px solid var(--color-primary-5);
}

/* hover */
.container:hover {
  box-shadow: 0px 13px 11px -9px rgba(41, 20, 152, 0.42);
}

/* disabled */
.container:disabled {
  background: var(--color-disabled2);
  pointer-events: none;
}

/*black variant*/
.black {
  background: var(--color-primary-3);
}

.container:focus {
  border: 1px solid var(--color-primary-2 );
}

/*outline variant*/
.outline {
  border: 1px solid var(--color-primary-6);
  background: inherit;
  color: var(--color-primary-3);
}

.outline:focus {
  border: 1px solid var(--color-primary-6);
}

.outline:hover {
  box-shadow: none;
}

/* underline variant */
.underline {
  width: fit-content;
  height: fit-content;
  background: transparent;
  color: #000000;
  font-size: 14px;
  text-decoration: underline;
  font-weight: 400;
}

.underline:focus {
  border: none;
}

.underline:hover {
  background: transparent;
  box-shadow: none;
}

.warning {
background-color: var(--color-danger);
}

@media screen and (max-width: 1599px) {
}

@media screen and (max-width: 1439px) {

}

@media screen and (max-width: 1219px) {
}

@media screen and (max-width: 1023px) {

}

@media screen and (max-width: 719px) {

}
