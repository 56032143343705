.container {
    color: var(--color-primary-3);
    font-family: var(--font-graphik);
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 100%;
    text-align: left;
    letter-spacing: -0.04em;
}

.variant_white {
    color: var(--color-white);
}


.title_code {
    /*width: 100%!important;*/
    /*display: flex;*/
    /*justify-content: center!important;*/
}