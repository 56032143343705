.container {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
}

.container__inner {
    background: white;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    width: 400px;
}

.container__inner_big {
    width: 420px;
}

.title {
    text-align: center;
    margin-bottom: 30px;
    font-size: 28px;
}

.icon {
    margin-bottom: 30px;
}

.icon > svg {
    margin: 0 auto;
    display: block;
}

.message {
    text-align: center;
    width: 100%;
    font-size: 18px;
    margin-bottom: 40px;
}

.group_buttons {
    display: flex;
    width: 100%;
}

.button {
    padding: 5px 15px;
}

.group_buttons > .button:first-child {
    margin-right: 15px;
}