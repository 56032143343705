.container {
    border-radius: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: black;
    font-family: var(--font-inter);
    border: 1px solid #7879F1;
    transition: .3s;

}

/* margin */
.margin--medium {
    margin-bottom: 30px;
    margin-top: 30px;
}

.container:focus {
    border: 1px solid var(--color-primary-5);
}

/* hover */
.container:hover {
    box-shadow: var(--box-shadow1);
}

/* disabled */
.container:disabled {
    background: var(--color-disabled2);
    pointer-events: none;
}

/*black variant*/
.black {
    background: var(--color-primary-3);
}

/* decline variant */
.decline {
    background: var(--color-primary-3);
    border-radius: 8px;
}

.container:focus {
    border: 1px solid var(--color-primary-2);
}

/*outline variant*/
.outline {
    border: 1px solid var(--color-primary-6);
    background: inherit;
    color: var(--color-primary-3);
}

.outline:focus {
    border: 1px solid var(--color-primary-6);
}

.outline:hover {
    box-shadow: none;
}

/* underline variant */
.underline {
    width: 288px;
    /*width: fit-content;*/
    /*height: fit-content;*/
    background: transparent;
    color: var(--color-primary-6);
    font-size: 14px;
    text-decoration: underline;
    font-weight: 400;
    border: none;
}

.underline:focus {
    border: none;
}

.underline:hover {
    background: transparent;
    box-shadow: none;
}

/*disabled*/
.container:disabled {
    background: var(--color-disabled2);
    pointer-events: none;
    border: none;
}

.disabled {
    width: fit-content;
    height: fit-content;
    color: #7879F1;
    font-size: 14px;
    font-weight: 500;
    background: #F2F2F2;
    border: none;
}

.disabled:focus {
    border: none;
}

.disabled:hover {
    box-shadow: none;
    background: #F2F2F2;
    cursor: not-allowed;
    border: none;
}

.container.purple {
    width: 100%;
    background: var(--color-primary-4);
    color: white;
    height: 48px;
}

.container.purple:disabled {
    background: var(--color-disabled2);
    pointer-events: none;
}

.loaderIcon path {
    fill: #FFFFFF;
}

.loaderIcon {
    width: 22px;
    height: 22px;
    right: 15px;
    position: absolute;
    animation: rotation 1s linear forwards infinite;
}

.container.submit {
    width: 288px;
    height: 48px;
    font-size: 16px;
    color: #FFFFFF;
    border-radius: 8px;
    background-color: #7742F4;
}

.container.submit:disabled {
    background: var(--color-disabled2);
    pointer-events: none;
}

.loading {
    position: relative;
    pointer-events: none;
}

@keyframes rotation {
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(360deg);
    }
}
