@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display Ultralight'), local('SFProDisplay-Ultralight'),
    url('SFProDisplay-Ultralight.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display Ultralight'), local('SFProDisplay-Ultralight'),
    url('SFProDisplay-Light.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display Semibold'), local('SFProDisplay-Semibold'),
    url('SFProDisplay-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display Semibold'), local('SFProDisplay-Semibold'),
    url('SFProDisplay-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display Semibold'), local('SFProDisplay-Semibold'),
    url('SFProDisplay-Semibold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display Semibold'), local('SFProDisplay-Semibold'),
    url('SFProDisplay-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display Semibold'), local('SFProDisplay-Semibold'),
    url('SFProDisplay-Heavy.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display Semibold'), local('SFProDisplay-Semibold'),
    url('SFProDisplay-Heavy.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;

}