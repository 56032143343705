

.button {
    margin-top: 33px ;
    box-shadow: 0px 13px 15px #D2DCFF;
    border-radius: 10px!important;
    height: 49px!important;
    width: 282px!important;
}

.button:disabled {
    box-shadow: none;
}

.phone_input {
    margin-top: 62px;
    width: 310px;
}

.phone_input > label {
    font-family: var(--font-graphik);
    font-style: normal;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 16px !important;
    transform: translateY(-1px) !important;
    /* identical to box height */


    color: #767676;

}

.phone_input > input {
    padding-bottom: 0 !important;
    font-size: 25px !important;
    font-family: var(--font-graphik);
    height: 34px!important;
    line-height: 25!important;
}
