.wrapper {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    flex-direction: column;
}

.CodeField {
    display: grid;
    position: relative;
    grid-template-columns: repeat(4, 32px);
    grid-column-gap: 16px;
    margin-bottom: 30px;
}

.input_wrapper {
    width: 32px;
    height: 45px;
    position: relative;
}

.input_wrapper::after {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 1px;
    background: #B0B2C6;
    border-radius: 50px;
    bottom: 0;
    left: 0;
}

.input_wrapper__filled::after {
    background: var(--color-primary-4);
}



.input_wrapper__error::after {
    background: var(--color-primary-3);
}

.timer {
    width: 225px;
    position: relative;
}

.timer_text {
    font-family: var(--font-sf);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #656565;

}

/* input state */
.wrapper input {
    font-family: var(--font-sf);
    width: 100%;
    color: #000000;
    flex: 1;
    margin-right: 10px;
    height: 42px;
    text-align: center;
    outline: none;
    background: none;
    border: none;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    padding: 0 !important;
}

.input_wrapper__error input {
    color: var(--color-danger);
}

.wrapper input::-webkit-outer-spin-button,
.wrapper input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.wrapper input:last-child {
    margin-right: 0;
}

/* error */
.wrapper_error_number {
    background: var(--color-danger);
}

.error {
    position: absolute;
    top: calc(100% + 6px);
    text-align: center;
    font-size: 14px;
    color: var(--color-danger);
    width: 100%;
}
.line {
    display: block;
    width: 292px;
    height: 1px;
    background-color: #CCD7DE;
    margin-bottom: 0px;
}
.repeat_title {
    width: 100%;
    height: 20px;
    font-family: var(--font-sf);
    font-style: normal;
    font-weight: 500;
    text-align: center;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
}

.repeat_title span {
    font-weight: 700;
}

.resend_code {
    cursor: pointer;
    color: #16142B;
    text-decoration: underline;
text-align: left;
}

.resend_code--disabled {
    color: gray;
    cursor: not-allowed;
    pointer-events: none;
}