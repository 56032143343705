.LoaderPage_container {
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;

    opacity: 1;
    animation: fade 1s linear infinite;
}

.LoaderPage_bird {
    width: 100px;
    height: 100px;
}


@keyframes fade {
    0%,100% { opacity: 0.65 }
    50% { opacity: 1 }
}