.container {
    margin-top: 72px;
}

.input {
    width: 282px!important;
}

.input > label {
    font-family: var(--font-graphik);
    transform: translateY(10px)!important;
}

.input > input {
    padding-bottom: 0!important;
    font-family: var(--font-graphik);
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 25px;
}

.button {
    margin-top: 32px;
}

.button {
    box-shadow: 0px 13px 15px #D2DCFF;
    border-radius: 10px;
    width: 282px;
    height: 49px;
}

.forgot_password {
    margin-top: 20px;
    font-family: var(--font-graphik);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 129%;
    color: var(--color-primary-10);
}
