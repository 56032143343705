.input_wrapper {
    position: relative;
}

.wrapper {
    --label-offset: -5px;
    padding-bottom: 24px;
    padding-top: 24px;
    min-height: 78px;
}

.Input {
    border-radius: 0;
    border: 0;
    width: 100%;
    border-bottom: 1px solid var(--color-primary-3);
    font-family: var(--font-graphik);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: var(--color-primary-3);
    outline: none;
    position: relative;
    z-index: 3;
    background: transparent;
    /*text-overflow: ellipsis;*/
    padding-right: 30px;
    padding-bottom: 4px;
    height: 28px;
}

.textAria {
    height: auto;
    resize: none;
    white-space: pre-wrap;
}


.Input_large {
    font-size: 18px;
}

.label {
    font-family: var(--font-sf);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
}

.Input_large + .label {
    font-size: 14px;
}

.Input::placeholder {
    opacity: 0;
}

.Input + .label {
    position: absolute;
    top: 0;
    /*bottom: 53px;*/
    transform: translateY(100%);
    left: 0;
    transition: .2s;
    color: var(--color-grey3);
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 400;
}

.Input:not(:placeholder-shown) + .label,
.Input:focus + .label,
.Input.filled + .label {
    /*transform: translateY(-11px);*/
    position: absolute;
    top: -10px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 300;
}

[data-class="dim"] > path {
    fill: var(--color-grey2);
}

.Input:not(:placeholder-shown) ~ .support [data-class="dim"] > path,
.Input:focus ~ .support [data-class="dim"] > path,
.Input.filled ~ .support [data-class="dim"] > path {
    fill: var(--color-primary-3);
}

.Input_large.Input:not(:placeholder-shown) + .label,
.Input_large.Input:focus + .label,
.Input_large.Input.filled + .label {
    font-size: 14px;
}

.Input_error {
    border-color: var(--color-danger);
}

.Input:focus {
    border-bottom: 1px solid var(--color-primary-4);
}

.Input:focus .label {
    color: #16142B;
}


/* чтобы при фокусе бордер не был синим на readonly инпуте */
.Input_readonly {
    pointer-events: none;
    border-bottom: 1px solid #C5C9D4;
    color: #C5C9D4;
}

.Input_readonly + .label {
    color: #C5C9D4;
}

.Input_readonly ~ .support {
    cursor: default;
}


.support {
    position: absolute;
    right: 0;
    bottom: 28px;
    z-index: 4;
    cursor: pointer;
    fill: #AEB1BC;
    height: 24px;
}

.support_slim {
    bottom: 5px;
    right: 5px;
}

.Input:focus ~ .icon path {
    stroke: #002BC2;
}

.error {
    font-family: var(--font-inter);
    position: absolute;
    bottom: 5px;
    left: 6px;
    font-weight: 300;
    color: var(--color-danger);
    font-size: 10px;
    height: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: calc(100% - 6px);
}

.error:hover {
    overflow: visible;
}

.Input_large ~ .error {
    /*font-size: 14px;*/
}

.error_exclamation_icon {
    margin-right: 9px;
}

/*  */

.margin-top-30px {
    margin-top: 30px;
}

.password > input {
    letter-spacing: 0.15px;
    /* moz and safari fix */
    font: small-caption;
    font-size: 16px;
}

.exclamation_mark {
    margin-right: 9px;
    font-weight: 400;
}


/*Variant_none*/

.Input_none {
    border-bottom: none;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    /*line-height: 24px;*/
}

.label_none {
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
    color: #9CA1AB;

}

.Input_text_aria {
    font-family: var(--font-inter);
    width: 100%;
    height: 61px !important;
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    border-radius: 7px;
    margin-top: 12px;
    outline: none;
    -moz-appearance: none;
    resize: none;
    padding: 9px 5px 3px 16px;
    margin-bottom: 24px;
    transition: background-color 0.3s ease 0s;
}

.Input_code {
    width: 32px;
    height: 35px;
    margin: 8px;
    /* padding-bottom: 0 и padding-left: 0 нужны для ios, т.к. по-дефолту там все съезжает */
    padding: 5px 0 0 0;
    text-align: center;
    font-family: var(--font-cera);
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    border-bottom: 1px solid var(--color-disabled2);
}

.Input_code:valid {
    border-bottom: 1px solid var(--color-primary-4);
}

.Input_code:invalid {
    border-bottom: 1px solid var(--color-disabled2);
}

@media (max-width: 1620px) {
    .Input_text_aria {
        width: 100%;
    }
}
