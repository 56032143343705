.container {
    display: flex;
    width: 100%;
    height: 100vh;
}

.content_container  {
    margin-left: auto;
    width: auto;
    flex-grow: 1;
    width: 65%;

    /* padding: 208px 135px 135px 291px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


}


/* Image part */
.right_image_part {
    z-index: -1;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;

    display: block;
    height: 100vh;
}



.right_image_part img {
    /* display: block;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: 100vh; */

    /*object-fit: cover;*/
    object-fit: none;
    width: 100%;
    height: 100%;
}

.image_title {
    position: absolute;
    bottom: 10%;
    left: 10%;
    color: #FFFFFF;

    font-family: var(--font-cera);
    font-size: 64px;
    font-weight: 400;
    line-height: 70px;
    letter-spacing: -0.04em;
    text-align: left;
}

.creddy_logo {
    display: block;
    margin-bottom: 29px;
}

/* Gradient part */
.desktop_gradient {

    margin-left: -24px;
    /* TODO uncomment */
    z-index: -1;
    position: relative;
    overflow-y: hidden;
    width: 60%;
    height: 100vh;
    background: linear-gradient(255.41deg, #B340FA -10.33%, #7143F3 56.79%, #5262DA 84.88%), #5262DA;
}

.triangle_1 {
    position: absolute;
    right: 0;
    top: 5px;
    z-index: 3;
    height: 80%;
}

.triangle_2 {
    position: absolute;
    top: 18%;
    right: 0;
    height: 80%;
}

.content_container > div {
    width: 387px;
}

.container_patch > div {
    width: auto !important;
}

.subtitle {
    margin-top: 20px;
    margin-bottom: 10px;
}

.phone_text {
    color: var(--color-black);
    margin-top: 12px;
    display: flex;
    align-items: center;
    width: 282px;
    justify-content: center;
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 25px;
}

.phone_text_subTitle {
    width: 282px;
    text-align: center;
}

.pencil_icon {
    margin-left: 7px;
    cursor: pointer;
}

@media screen and (max-width: 1919px) {

}


@media screen and (max-width: 1679px) {

}

/* ноут маленький */
@media screen and (max-width: 1365px) {
    
    .content_container {
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
    }

    .creddy_logo {
        width: 200px;
        height: 75px;
        margin-bottom: 10px;
    }

    .image_title {
        font-size: 32px;
        line-height: 36px;
    }

}

@media screen and (max-width: 1023px) {
    .container {
        display: block;
        height: calc(100vh - 85px);
    }

    .content_container {
        display: block;

        margin-left: 0;
        width: 100%;
    }

    .right_image_part {
        display: none;
    }

    .desktop_gradient {
        display: none;
    }
    
    .title {
        margin-bottom: 9px;
    }

    .content_container {
        padding: 48px 16px;
        border-radius: 24px 24px 0 0;
        background: #F8F8F8;
        height: 100%;
    }
    
    .phone_text {
        color: var(--color-primary-10);
        font-size: 18px;
        font-weight: 500;
        margin-top: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .pencil_icon {
        margin-left: 7px;
        cursor: pointer;
    }

    .content_container > div {
        width: auto;
    }
}
