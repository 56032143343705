.container {
    animation: rotation 1s linear forwards infinite;
}

.container--large {
    width: 80px;
    height: 80px;
}

@keyframes rotation {
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(360deg);
    }
}

.color--white path {
    fill: #FFFFFF;
}

.colorWhite path {
    fill: #FFFFFF;
}