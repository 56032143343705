:root {
    --color-white: #FFFFFF;
    --color-white-1: #F8F8F8;
    --color-white3: #F2F2F2;

    --color-primary: #182EC4;
    --color-primary-2: #6B83D9;
    --color-primary-3: #16142B;
    --color-primary-4: #7742F4;
    --color-primary-5: #A5A6F6;
    --color-primary-6: #3043CA;
    --color-primary-7: #7242F3;
    --color-primary-8: #9041F6;
    --color-primary-9: #7957D7;
    --color-iris: #7879F1;
    --color-primary-11: #7143F3;
    --color-primary-10: #7879F1;

    --color-primary-black: #16142B;

    --color-danger: #F82412;
    --color-danger-2: #FA4E40;
    --color-danger-3: #FB5F52;

    --color-error-border: #FFA199;

    --color-grey: #AEB1BC;
    --color-grey2: #7D858B;
    --color-grey3: #9CA1AB;
    --color-grey-4: #C0CBD1;
    --color-grey-5: #E6EBEE;
    --color-grey-6: #F5F5F5;
    --color-disabled: #D2D6E1;
    --color-disabled2: #C5C9D4;

    --color-black: #000000;
}
