@import "fonts/sf/index.css";
@import "fonts/inter/stylesheet.css";
@import "fonts/CeraPro/index.css";
@import "fonts/GraphikLCG/index.css";

:root {
    --font-sf: "SF", sans-serif;
    --font-inter: "Inter", sans-serif;
    --font-cera: "Cera Pro", sans-serif;
    --font-graphik: "Commissioner", sans-serif;
    font-family: var(--font-inter);
}
