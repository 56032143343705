.Grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 20px;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1652px;
    width: 100%;
    align-items: flex-start;
    align-content: flex-start;
}

@media screen and (max-width: 1919px) {
    .Grid {
        grid-gap: 20px;
        max-width: 1460px;
    }
}

@media screen and (max-width: 1679px) {
    .Grid {
        grid-gap: 16px;
        padding: 0 20px;
        max-width: 1224px;
    }
}

@media screen and (max-width: 1365px) {
    .Grid {
        grid-template-columns: repeat(12, 1fr);
        max-width: 984px;
    }
}

@media screen and (max-width: 1023px) {
    .Grid {
        grid-template-columns: repeat(8, 1fr);
        padding: 0 16px;
        max-width: 592px;
        margin-right: auto;
        margin-left: auto;
    }
}

/* На мобилу нет сетки в дизайн стартер паке */

@media screen and (max-width: 591px) {
    .Grid {
        grid-template-columns: repeat(4, 1fr);
        max-width: 100%;
    }
}

@media screen and (max-width: 389px) {

}