@font-face {
    font-family: 'Commissioner';
    src: local('Commissioner-Thin'), local('Commissioner-Thin'),
    url('Commissioner-Thin.ttf') format('ttf');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Commissioner';
    src: local('Commissioner-ExtraLight'), local('Commissioner-ExtraLight'),
    url('Commissioner-ExtraLight.ttf') format('ttf');
    font-weight: 200;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'Commissioner';
    src: local('Commissioner-Regular'), local('Commissioner-Regular'),
    url('Commissioner-Regular.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'Commissioner';
    src: local('Commissioner-Medium'), local('Commissioner-Medium'),
    url('Commissioner-Medium.ttf') format('ttf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'Commissioner';
    src: local('Commissioner-SemiBold'), local('Commissioner-SemiBold'),
    url('Commissioner-SemiBold.ttf') format('ttf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'Commissioner';
    src: local('Commissioner-Bold'), local('Commissioner-Bold'),
    url('Commissioner-Bold.ttf') format('ttf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'Commissioner';
    src: local('Commissioner-ExtraBold'), local('Commissioner-ExtraBold'),
    url('Commissioner-ExtraBold.ttf') format('ttf');
    font-weight: 800;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'Commissioner';
    src: local('Commissioner-Black'), local('Commissioner-Black'),
    url('Commissioner-Black.ttf') format('ttf');
    font-weight: 900;
    font-style: normal;
    font-display: swap;

}